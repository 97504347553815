import { FarmContractSolana, RewardConfig } from '@/blockchainHandlers/farm-contract-solana'
import { splPriceStore } from '@/blockchainHandlers/spl-token-helper'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { NO_TIER, tierHelper, Zero } from '@/constants'
import { bigNumberHelper, bnHelper } from '@/helpers/bignumber-helper'
import { walletStore } from '@/stores/wallet-store'
import { FixedNumber } from '@ethersproject/bignumber'
import { WalletSignTransactionError } from '@solana/wallet-adapter-base'
import { PublicKey } from '@solana/web3.js'
import { first, last } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, runInAction } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment, { duration } from 'moment'
import { Subject, Subscription, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

export class StakingViewModel {
  _disposers: IReactionDisposer[] = []
  private _unsubcrible = new Subject()

  @observable showApyConfigDialog = false

  @observable stakeDialogInput = ''
  @observable isShowStakeDialog = false
  @observable isDialogStaking = false
  @observable extendTimeOnly = false
  @observable isDialogLoading = false

  @observable harvesting = false

  @observable fundAmountInput = ''
  @observable fundState = false
  @observable rewardVaultAmount = FixedNumber.from('0')

  @observable userLockDuration = moment.duration(0, 'second') // in seconds
  @observable totalLiquidity = FixedNumber.from('0')
  @observable annualPercentageRate = FixedNumber.from('0')

  @observable stakedLP = FixedNumber.from('0')
  @observable userLPBalance = FixedNumber.from('0')
  @observable rewardAmount = FixedNumber.from('0')
  @observable lastStakeTime: moment.Moment | null = null
  @observable lockInDaysInput = 0
  @observable tokenPrice = FixedNumber.from('0')
  @observable rewardConfigs: RewardConfig[] = []
  @observable selectedRewardConfig: RewardConfig | undefined = undefined

  farmHandler?: FarmContractSolana
  _fetchUserInfoSubscription?: Subscription

  constructor() {
    this.loadData()
  }

  fetchData = true

  enableFetching() {
    if (!this.fetchData) {
      this.getWagPrice()
      if (this.rewardConfigs.length === 0) {
        this.fetchFarmInfo()
      }
    }
    this.fetchData = true
  }

  disableFetching() {
    this.fetchData = false
  }

  @action enableFundState() {
    this.fundState = true
  }

  @action.bound changeFundAmountInput(input) {
    this.fundAmountInput = input
  }

  @asyncAction *loadData() {
    this.getWagPrice()
    const farmHandler = FarmContractSolana.getInstance()
    yield this.fetchFarmInfo()
    timer(0, 120000).subscribe(async () => {
      if (this.fetchData) {
        this.fetchAPY()
        if (this.fundState) {
          const amount = await farmHandler.getRewardVaultAmount()
          runInAction(() => {
            this.rewardVaultAmount = amount
          })
        }
      }
    })
    reaction(
      () => walletStore.account,
      async () => {
        this._fetchUserInfoSubscription?.unsubscribe()
        farmHandler.clearUser()
        runInAction(() => {
          this.stakedLP = FixedNumber.from('0')
          this.rewardAmount = FixedNumber.from('0')
          this.userLockDuration = duration(0)
        })
        if (walletStore.account && walletStore.solanaConnected) {
          farmHandler.injectProvider(walletStore.connectedSolProvider)
          this._fetchUserInfoSubscription = timer(0, 60000)
            .pipe(takeUntil(this._unsubcrible))
            .subscribe(async () => {
              this.fetchPoolInfo()
            })
        }
      },
      { fireImmediately: true }
    )
  }

  @asyncAction *fetchFarmInfo() {
    try {
      const farmHandler = FarmContractSolana.getInstance()
      yield farmHandler.load()
      this.farmHandler = farmHandler
      this.rewardConfigs = yield farmHandler.getRewardConfigs()
      this.selectedRewardConfig = last(this.rewardConfigs)
    } catch (error) {
      //
    }
  }

  private getWagPrice() {
    if (this.tokenPrice.isZero()) {
      splPriceStore
        .getRaydiumPriceByAddress(new PublicKey('5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E'))
        .then((x) => runInAction(() => (this.tokenPrice = FixedNumber.from(`${x}`))))
    }
  }

  @asyncAction *fetchAPY() {
    const farmHandler = this.farmHandler as any
    yield farmHandler.load()
    const { totalStakedAmount, apy } = farmHandler.getPoolInfor()
    this.totalLiquidity = totalStakedAmount
    this.annualPercentageRate = apy
  }

  async fetchPoolInfo() {
    if (!walletStore.account || !this.farmHandler) {
      this.stakedLP = FixedNumber.from('0')
    } else {
      const { amount, lastStakeTime, lockDuration } = await this.farmHandler.getUserInfo(walletStore.account)
      const [userLPBalance, rewardAmount] = await Promise.all([
        this.farmHandler.getUserRewardBalance(walletStore.account),
        this.farmHandler.getPendingRewardAmount(walletStore.account),
      ])
      if (lockDuration) {
        this.userLockDuration = lockDuration
      } else {
        this.userLockDuration = duration(0, 'second')
      }
      this.stakedLP = amount
      this.lastStakeTime = lastStakeTime
      this.userLPBalance = userLPBalance
      this.rewardAmount = rewardAmount
    }
  }

  @asyncAction *harvest() {
    this.harvesting = true
    try {
      if (!this.farmHandler) throw 'farmHandler is undefined'
      yield this.farmHandler.harvest(walletStore.account)
      this.fetchAPY()
      this.fetchPoolInfo()
      snackController.success('Harvest successfully')
    } catch (error: any) {
      snackController.error(error.msg || error.message)
    }
    this.harvesting = false
  }

  @action.bound changeStakeDialogInput(input) {
    this.stakeDialogInput = input
  }

  stakeInputDefault(tierCarousel) {
    const currentTier = tierCarousel.indicatorHelper[tierCarousel.current]
    const stakedAmountTier = FixedNumber.from(`${currentTier.amount}`)
    const sub = this.userLPBalance.addUnsafe(this.stakedLP).subUnsafe(stakedAmountTier)
    if (bigNumberHelper.gte(sub, Zero)) {
      if (bigNumberHelper.gt(this.stakedLP, stakedAmountTier)) return this.userLPBalance.toString()
      return stakedAmountTier.subUnsafe(this.stakedLP).toString()
    } else {
      return this.userLPBalance.toString()
    }
  }

  unstakeInputDefault(tierCarousel) {
    const currentTier = tierCarousel.indicatorHelper[tierCarousel.current]
    const stakedAmountTier = FixedNumber.from(`${currentTier.amount}`)
    const sub = this.stakedLP.subUnsafe(stakedAmountTier)
    if (bigNumberHelper.gte(sub, Zero)) {
      return sub.toString()
    } else {
      return ''
    }
  }

  @action.bound requestStakeLP(tierCarousel, extendonly = false) {
    this.stakeDialogInput = this.stakeInputDefault(tierCarousel)
    this.isShowStakeDialog = true
    this.isDialogStaking = true
    this.extendTimeOnly = extendonly
    if (extendonly) {
      this.stakeDialogInput = ''
    }
    this.lockInDaysInput = this.defaultLockInDays
    if (this.userLockDuration.asSeconds() > 0) {
      this.selectedRewardConfig = [...this.rewardConfigs]
        .reverse()
        .find((x) => this.userLockDuration.asSeconds() >= x.duration.asSeconds())
      if (!this.selectedRewardConfig) this.selectedRewardConfig = first(this.rewardConfigs)
    } else {
      this.selectedRewardConfig = last(this.rewardConfigs)
    }
  }

  @action.bound requestUnstakeLP(tierCarousel) {
    this.stakeDialogInput = this.unstakeInputDefault(tierCarousel)
    this.isShowStakeDialog = true
    this.isShowStakeDialog = true
    this.isDialogStaking = false
  }

  @action.bound onMultipleChange(multiple = 0) {
    this.stakeDialogInput = FixedNumber.from(`${multiple}`).mulUnsafe(this.maxDialogStakeBalance).toString()
  }

  @action.bound selectRewardConfig(config) {
    this.selectedRewardConfig = config
  }

  @asyncAction *confirm() {
    this.isDialogLoading = true
    try {
      if (!this.farmHandler) throw 'farmHandler is undefined'
      if (this.isDialogStaking && this.selectedRewardConfig) {
        yield this.farmHandler.stake(
          walletStore.account,
          this.validStakeDialogInput,
          this.selectedRewardConfig.duration.asSeconds()
        )
        snackController.success('Stake WAG successfully')
      } else {
        yield this.farmHandler.unstake(walletStore.account, this.validStakeDialogInput)
        snackController.success('Unstake WAG successfully')
      }
      this.fetchAPY()
      this.fetchPoolInfo()
      this.stakeDialogInput = '0'
      this.isShowStakeDialog = false
    } catch (error: any) {
      snackController.error(error.msg || error.message)
      if (error instanceof WalletSignTransactionError) {
        this.isShowStakeDialog = false
      }
    } finally {
      this.isDialogLoading = false
    }
  }

  @observable funding = false;

  @asyncAction *fund() {
    try {
      this.funding = true
      let amount = FixedNumber.from('0')
      try {
        amount = FixedNumber.from(this.fundAmountInput)
      } catch (error) {
        snackController.error('Invalid amount')
        return
      }
      if (bnHelper.gte(this.userLPBalance, amount)) {
        if (!this.farmHandler) throw 'farmHandler is undefined'
        yield this.farmHandler.fund(walletStore.account, amount)
        this.fundAmountInput = ''
        snackController.success('Fund successfully')
        this.farmHandler.getRewardVaultAmount().then((am) => {
          runInAction(() => {
            this.rewardVaultAmount = am
          })
        })
      } else {
        snackController.error('Over max')
      }
    } catch (error: any) {
      snackController.error(error.msg || error.message)
    } finally {
      this.funding = false
    }
  }

  @action.bound telegramChanelSupport() {
    window.open('https://t.me/wagglenetwork', '_blank')
  }

  @action.bound cancelStakeDialog() {
    this.isShowStakeDialog = false
  }

  @action.bound getWag() {
    window.open(`https://raydium.io/swap/?ammId=FEFzBbbEK8yDigqyJPgJKMR5X1xZARC25QTCskvudjuK`, '_blank')
  }

  @action.bound setApyConfigDialog(val) {
    this.showApyConfigDialog = val
  }

  @computed get validStakeDialogInput() {
    let res
    try {
      res = FixedNumber.from(this.stakeDialogInput)
    } catch {
      res = Zero
    }
    return res
  }

  @computed get totalStakedAmount() {
    return this.isDialogStaking
      ? this.stakedLP.addUnsafe(this.validStakeDialogInput).toString()
      : this.stakedLP.subUnsafe(this.validStakeDialogInput).toString()
  }

  @computed get getTierMatchAmount() {
    const amount = this.isDialogStaking
      ? this.stakedLP.addUnsafe(this.validStakeDialogInput)
      : this.stakedLP.subUnsafe(this.validStakeDialogInput)
    const tier = tierHelper.findTierByAmount(amount.toUnsafeFloat())
    return tier ? tier.name : NO_TIER
  }

  @computed get isStaked() {
    return bigNumberHelper.gt(this.stakedLP, FixedNumber.from('0'))
  }

  @computed get maxDialogStakeBalance() {
    return this.isDialogStaking ? this.userLPBalance : this.stakedLP
  }

  @computed get lockInDays() {
    return this.userLockDuration.asDays()
  }

  @computed get defaultLockInDays() {
    return this.userLockDuration.asDays()
  }
  @computed get validLockDuration() {
    return this.defaultLockInDays <= this.lockInDaysInput
  }

  @computed get canUnstakeTime() {
    if (this.lastStakeTime) {
      return this.lastStakeTime.clone().add(this.userLockDuration)
    }
    return null
  }
  @computed get userExtraReward() {
    const config = [...this.rewardConfigs]
      .reverse()
      .find((x) => this.userLockDuration.asSeconds() >= x.duration.asSeconds())
    return config?.extraPercentage || Zero
  }

  @computed get canHarvest() {
    return bigNumberHelper.gt(this.rewardAmount, Zero)
  }

  @computed get isSelectedBest() {
    return this.selectedRewardConfig === last(this.rewardConfigs)
  }
  @computed get tvl() {
    return this.totalLiquidity.mulUnsafe(this.tokenPrice)
  }

  @computed get baseOneYearReward() {
    const totalAmount = FixedNumber.from(`${this.totalStakedAmount || 0}`)
    const reward = totalAmount.mulUnsafe(this.annualPercentageRate).divUnsafe(FixedNumber.from('100'))
    return reward
  }

  @computed get oneYearReward() {
    const selectedConfig = this.selectedRewardConfig
    const bonus = this.baseOneYearReward
      .mulUnsafe(selectedConfig?.extraPercentage || Zero)
      .divUnsafe(FixedNumber.from('100'))
    return this.baseOneYearReward.addUnsafe(bonus)
  }

  @computed get validDialogInputAmount() {
    try {
      const amount = this.validStakeDialogInput
      if (this.isDialogStaking && !this.validLockDuration) return false
      if (!this.isDialogStaking && this.canUnstakeTime && this.canUnstakeTime.isAfter(moment())) return false
      if (amount.isNegative()) return false
      if (amount.isZero()) {
        if (this.isDialogStaking) {
          return (
            !this.stakedLP.isZero() &&
            this.userLockDuration.asSeconds() !== (this.selectedRewardConfig?.duration.asSeconds() || 0)
          )
        } else {
          return false
        }
      }
      if (this.isDialogStaking) {
        return bigNumberHelper.lte(amount, this.userLPBalance)
      } else {
        return bigNumberHelper.lte(amount, this.stakedLP)
      }
    } catch (error) {
      return false
    }
  }

  @computed get stakeLockedUntil() {
    return moment().add(this.selectedRewardConfig?.duration.asSeconds() || 0, 'second')
  }

  @computed get requiredChainId() {
    return process.env.VUE_APP_FARM_NETWORK === 'production' ? 101 : 103
  }

  @computed get maxApy() {
    return this.annualPercentageRate.mulUnsafe(FixedNumber.from('2'))
  }

  @computed get yourApy() {
    const config = [...this.rewardConfigs]
      .reverse()
      .find((x) => this.userLockDuration.asSeconds() >= x.duration.asSeconds())
    return this.annualPercentageRate.mulUnsafe(config?.boost || FixedNumber.from('1'))
  }

  @computed get isUserLocked1Year() {
    return this.userLockDuration.asSeconds() === 365 * 24 * 60 * 60
  }
}

export const stakingVm = new StakingViewModel()
