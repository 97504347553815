



















































































































































































































































































































































import { stakingVm } from '../viewmodels/staking-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { walletStore } from '@/stores/wallet-store'
import { tierHelper } from '@/constants'

@Observer
@Component({
  components: {
    StakingCarousel: () => import('../components/staking-carousel.vue'),
    'staking-lp-dialog': () => import('../dialogs/staking-lp-dialog.vue'),
    'apy-config-dialog': () => import('../dialogs/apy-config-dialog.vue'),
  },
})
export default class Staking extends Vue {
  @Provide() vm = stakingVm
  walletStore = walletStore
  lst = tierHelper.getTierList()

  @Watch('$route.query.fund', { immediate: true }) async onFund(val: string) {
    if (val) {
      this.vm.enableFundState()
    }
  }

  mounted() {
    this.vm.enableFetching()
  }

  viewProgram() {
    //
  }
  destroyed() {
    this.vm.disableFetching()
  }
}
